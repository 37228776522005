import { API_DEFAULT } from "src/constants/minutApi"
import { TReadOnly } from "src/data/commonTypes"
import {
  TAlarmRecognition,
  TDevice,
  TListeningMode,
  TReactions,
  TReportInterval,
} from "src/data/devices/types/deviceTypes"
import {
  IDropdown,
  INumberInput,
  IRangeSlider,
  ISection,
  ISlider,
  IStatic,
  ITextInput,
  IToggle,
  IToggleSection,
} from "src/data/editField/editFieldTypes"
import { TClockTypeMaybe, TTemperatureUnit } from "src/data/user/user"

//Todo move this to another place?
export const DeviceSettingMixin = {
  endpointUrl: (device: IDeviceSettings) =>
    `${API_DEFAULT}/devices/${device.device_id}`,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Input {
  export type Dropdown = IDropdown<IDeviceSettings, IDeviceSettingsPayload>
  export type Number = INumberInput<IDeviceSettings, IDeviceSettingsPayload>
  export type RangeSlider = IRangeSlider<
    IDeviceSettings,
    IDeviceSettingsPayload
  >
  export type Slider = ISlider<IDeviceSettings, IDeviceSettingsPayload>
  export type Static = IStatic<IDeviceSettings>
  export type Text = ITextInput<IDeviceSettings, IDeviceSettingsPayload>
  export type Toggle = IToggle<IDeviceSettings, IDeviceSettingsPayload>
}
export type Section = ISection<IDeviceSettings, IDeviceSettingsPayload>
export type SubSection = IToggleSection<IDeviceSettings, IDeviceSettingsPayload>

/** Payload sent to backend when updating settings */
export interface IDeviceSettingsPayload {
  alarm_recognition?: TAlarmRecognition
  description?: string
  home?: string
  listening_mode?: TListeningMode
  nightlight_mode?: "timebased" | "off"
  nightlight_start_at?: string
  nightlight_end_at?: string
  power_saving_mode?: "off" | "on"
  quiet_hours?: {
    enabled: boolean
    start: string /* 24h */
    end: string /* 24h */
  }
  report_interval?: TReportInterval
  reactions?: TReactions[]
  room_type?: string
  placed_outdoors?: boolean
  always_notify_when_noisy?: boolean
}

/**
 * Interface for the data we receive before populating settings locally
 *
 * The frontend settings should mirror the payload sent, but with all keys
 * required & readonly.
 */
export type IDeviceSettings = TReadOnly<
  TDevice & { temperature_unit: TTemperatureUnit; clockType: TClockTypeMaybe }
>
