import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { ErrorService } from "src/utils/ErrorService"

export function AlarmRecognitionSetting({
  deviceId,
  alarmRecognition,
}: {
  deviceId: string
  alarmRecognition: TDevice["alarm_recognition"]
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const putDevice = usePutDevice(orgId)

  async function handleSave(
    newValue: boolean
  ): TSettingContainerOnSaveReturnType {
    try {
      await putDevice.mutateAsync({
        id: deviceId,
        data: { alarm_recognition: transformValueToAlarmRecognition(newValue) },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      ErrorService.captureException(error)

      return {
        isSuccess: false,
      }
    }
  }

  return (
    <SettingToggle
      title={t(langKeys.alarm_recognition_setting_title)}
      description={t(langKeys.alarm_recognition_setting_body)}
      onSave={handleSave}
      value={transformAlarmRecognitionToValue(alarmRecognition)}
    />
  )
}

function transformAlarmRecognitionToValue(
  status: TDevice["alarm_recognition"]
): boolean {
  if (status === "on") {
    return true
  }

  return false
}

function transformValueToAlarmRecognition(
  value: boolean
): TDevice["alarm_recognition"] {
  if (value) {
    return "on"
  }

  return "off"
}
