import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { ErrorService } from "src/utils/ErrorService"

export function WindowBreakDetectionSetting({
  deviceId,
  listeningMode,
}: {
  deviceId: string
  listeningMode: TDevice["listening_mode"]
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const putDevice = usePutDevice(orgId)

  async function handleSave(
    newValue: boolean
  ): TSettingContainerOnSaveReturnType {
    try {
      await putDevice.mutateAsync({
        id: deviceId,
        data: { listening_mode: transformValueToListeningMode(newValue) },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      ErrorService.captureException(error)

      return {
        isSuccess: false,
      }
    }
  }

  return (
    <SettingToggle
      title={t(langKeys.device_settings_glassbreak)}
      description={t(langKeys.device_settings_glassbreak_detail)}
      onSave={handleSave}
      value={transformListeningModeToValue(listeningMode)}
    />
  )
}

function transformListeningModeToValue(
  listeningMode: TDevice["listening_mode"]
): boolean {
  if (listeningMode === "on") {
    return true
  }

  return false
}

function transformValueToListeningMode(
  value: boolean
): TDevice["listening_mode"] {
  if (value) {
    return "on"
  }

  return "interval"
}
