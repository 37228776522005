import { useState } from "react"

import { AxiosError } from "axios"

import { useDeleteDevice } from "src/data/devices/queries/deviceQueries"
import { IDeleteDeviceError } from "src/data/devices/types/deviceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { ErrorService } from "src/utils/ErrorService"

const enum DeviceErrorKey {
  INSUFFICIENT_ACCESS = "insufficient_access",
}

export function SettingRemoveDevice({ deviceId }: { deviceId: string }) {
  const [errorMsg, setErrorMsg] = useState("")
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()
  const { orgId } = useOrganization()

  const deleteDevice = useDeleteDevice(orgId)

  function onError(error: AxiosError<IDeleteDeviceError>) {
    if (
      error.response?.data?.error_key === DeviceErrorKey.INSUFFICIENT_ACCESS
    ) {
      setErrorMsg(t(langKeys.not_enough_permissions))
      return
    }

    ErrorService.captureException(error)
  }

  function onSuccess() {
    setErrorMsg("")
    setShowConfirmRemove(false)
    navigate(Routes.Sensors.location())
  }
  async function handleRemove() {
    deleteDevice.mutate({ deviceId }, { onSuccess, onError })
  }

  return (
    <div>
      <MButtonLegacy
        onClick={() => setShowConfirmRemove(true)}
        loading={deleteDevice.isLoading}
        size="large"
        variant="outlined"
        emergency
      >
        {t(langKeys.device_settings_remove_point)}
      </MButtonLegacy>
      <ConfirmDialog
        title={t(langKeys.device_settings_remove_point_alert_title)}
        open={showConfirmRemove}
        onClose={() => setShowConfirmRemove(false)}
        loading={deleteDevice.isLoading}
        confirmButtonProps={{ color: "destructive" }}
        confirmLabel={t(langKeys.device_settings_remove_point)}
        onConfirm={() => handleRemove()}
        description={t(langKeys.device_settings_remove_point_alert_body)}
        error={!!errorMsg && errorMsg}
      />
    </div>
  )
}
