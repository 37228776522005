import { useState } from "react"
import styled from "styled-components"

import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { SettingTimeRange } from "src/components/Settings/Setting/SettingTimeRange"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"

export function NightLightSetting({
  deviceId,
  nightLightMode,
  nightLightSlider,
}: {
  deviceId: string
  nightLightMode: TDevice["nightlight_mode"]
  nightLightSlider: string
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const [sliderRange, setSliderRange] = useState(nightLightSlider)
  const putDevice = usePutDevice(orgId)

  async function handleSaveNightlightMode(
    newValue: boolean
  ): TSettingContainerOnSaveReturnType {
    try {
      await putDevice.mutateAsync({
        id: deviceId,
        data: { nightlight_mode: transformValueToNightLightMode(newValue) },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      ErrorService.captureException(error)

      return {
        isSuccess: false,
      }
    }
  }

  async function handleSaveSliderRange(
    sliderValue: string
  ): TSettingContainerOnSaveReturnType {
    const [nightlight_start_at, nightlight_end_at] = sliderValue.split("-")

    setSliderRange(sliderValue)

    try {
      await putDevice.mutateAsync({
        id: deviceId,
        data: {
          nightlight_mode: "timebased",
          nightlight_start_at,
          nightlight_end_at,
        },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      ErrorService.captureException(error)

      return {
        isSuccess: false,
      }
    }
  }

  return (
    <SettingToggle
      title={t(langKeys.device_settings_nightlight)}
      description={t(langKeys.device_settings_nightlight_detail)}
      onSave={handleSaveNightlightMode}
      value={transformNightLightModeToValue(nightLightMode)}
    >
      <NightLightSlider>
        <SettingTimeRange
          title={t(langKeys.time)}
          value={sliderRange}
          onSave={handleSaveSliderRange}
        />
      </NightLightSlider>
    </SettingToggle>
  )
}

function transformNightLightModeToValue(
  status: TDevice["nightlight_mode"]
): boolean {
  if (status === "timebased") {
    return true
  }

  return false
}

function transformValueToNightLightMode(
  value: boolean
): TDevice["nightlight_mode"] {
  if (value) {
    return "timebased"
  }

  return "off"
}

const NightLightSlider = styled.div`
  margin: ${spacing.XL} 0 0 ${spacing.M};
  display: grid;
  gap: 1rem;
`
