import { useMemo } from "react"
import styled from "styled-components"

import {
  ISettingsViewConfig,
  SettingsView,
} from "src/components/Settings/SettingsView"
import { ISection } from "src/data/editField/editFieldTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"
import {
  IVerticalTabView,
  VerticalTabView,
} from "src/ui/Tabs/Vertical/VerticalTabView"
import { isString } from "src/utils/genericUtil"

export function SettingsTabbed<
  Settings,
  Payload,
  ResponseType = Settings,
>(props: {
  views: ISection<Settings, Payload>[]
  currentSettings: Settings[]
  iconMap?: (key: string) => JSX.Element
  config?: ISettingsViewConfig<Settings, ResponseType>
  title?: string | React.ReactNode
}) {
  const { _t } = useTranslate()

  const views = useMemo((): IVerticalTabView[] => {
    return props.views.map(
      ({ title, description, key, contents: settings }) => {
        const _title = typeof title === "function" ? title(_t) : _t(title)
        const _description =
          typeof description === "function"
            ? description(_t)
            : !!description
              ? _t(description)
              : undefined

        return {
          title: _title,
          description: _description,
          icon: props.iconMap ? props.iconMap(key) : null,
          view: (
            <SectionContainer>
              <SettingsView
                title={_title}
                subtitle={props.config?.subtitle}
                content={settings}
                currentSettings={props.currentSettings}
                viewConfig={props.config}
              />
            </SectionContainer>
          ),
        }
      }
    )
  }, [props, _t])

  const _title =
    props.title && isString(props.title)
      ? _t(props.title as string)
      : props.title

  return <VerticalTabView title={_title} views={views} />
}

const SectionContainer = styled.div`
  padding: ${spacing.M} ${spacing.M} ${spacing.M} ${spacing.XL};
  min-width: 500px;
  overflow: auto;
`
