import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import NoiseIcon from "src/ui/icons/noise-monitoring.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoiseMonitoringMoved() {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  return (
    <Box>
      <NoiseIcon width={48} height={48} style={{ marginBottom: spacing.L }} />

      <Content>
        <MText variant="heading2" color="primary">
          {t(langKeys.noise_thresholds_moved_title)}
        </MText>

        <MText color="primary">
          {t(langKeys.noise_thresholds_moved_description)}
        </MText>

        <div>
          <MButton
            variant="secondary"
            size="small"
            onClick={() => navigate(Routes.SettingsPresets.location())}
            fullWidth={false}
          >
            {t(langKeys.go_to_presets)}
          </MButton>
        </div>
      </Content>
    </Box>
  )
}

const Box = styled.div`
  text-align: center;
`

const Content = styled.div`
  display: grid;
  gap: ${spacing.M};
`
