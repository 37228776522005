import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { SettingDropdown } from "src/components/Settings/Setting/SettingDropdown"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import { useDeviceCache } from "src/data/devices/queries/deviceQueryCache"
import { TDeviceConfiguration } from "src/data/devices/types/deviceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { TMSelectOption } from "src/ui/MSelect/MSelect"
import { ErrorService } from "src/utils/ErrorService"

export function SelectSensorPlacement({
  deviceId,
  placedOutdoors,
  initialValue,
}: {
  deviceId: string
  placedOutdoors: boolean
  initialValue: TDeviceConfiguration["room_type"]
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const putDevice = usePutDevice(orgId)
  const deviceCache = useDeviceCache(orgId)

  const indoorAreas: TMSelectOption[] = [
    { label: t(langKeys.living_room), value: "living_room" },
    { label: t(langKeys.kitchen), value: "kitchen" },
    { label: t(langKeys.bedroom), value: "bedroom" },
    { label: t(langKeys.area_type_corridor), value: "corridor" },
    { label: t(langKeys.area_type_garage), value: "garage" },
    { label: t(langKeys.area_type_lounge), value: "lounge" },
    { label: t(langKeys.area_type_staircase), value: "staircase" },
    { label: t(langKeys.area_type_study), value: "study" },
    { label: t(langKeys.hallway), value: "hallway" },
    { label: t(langKeys.attic), value: "attic" },
    { label: t(langKeys.basement), value: "basement" },
    {
      label: t(langKeys.area_type_other_indoor_area),
      value: "other",
    },
  ].sort((a, b) => a.label.localeCompare(b.label))

  const outdoorAreas: TMSelectOption[] = [
    { label: t(langKeys.area_type_patio), value: "patio" },
    { label: t(langKeys.area_type_pool_area), value: "pool_area" },
    { label: t(langKeys.area_type_backyard), value: "backyard" },
    { label: t(langKeys.area_type_terrace), value: "terrace" },
    { label: t(langKeys.area_type_hot_tub), value: "hot_tub" },
    { label: t(langKeys.area_type_garden), value: "garden" },
    { label: t(langKeys.area_type_balcony), value: "balcony" },
    {
      label: t(langKeys.area_type_other_outdoor_area),
      value: "other_outdoor_area",
    },
  ].sort((a, b) => a.label.localeCompare(b.label))

  async function handleSave(area: string): TSettingContainerOnSaveReturnType {
    if (!area) {
      throw new Error("Value for area type is undefined")
    }

    try {
      const updatedDevice = await putDevice.mutateAsync({
        id: deviceId,
        data: { room_type: area },
      })

      // Invalidate queries in the same way as in deviceConfigurationView submit to update the state correctly.
      deviceCache.setCachedDeviceData(updatedDevice)

      return {
        isSuccess: true,
      }
    } catch (error) {
      ErrorService.captureException(error)
      return {
        isSuccess: false,
      }
    }
  }

  const areas = placedOutdoors ? outdoorAreas : indoorAreas
  const value =
    areas.find((area) => area.value === initialValue)?.value ??
    getDefaultArea(placedOutdoors).value
  const label = areas.find((area) => area.value === value)?.label

  function getDefaultArea(placedOutdoors: boolean): TMSelectOption {
    return placedOutdoors
      ? { label: t(langKeys.area_type_patio), value: "patio" }
      : { label: t(langKeys.hallway), value: "hallway" }
  }

  return (
    <SettingDropdown
      title={t(langKeys.device_settings_room_type)}
      description=""
      onSave={handleSave}
      value={value}
      options={areas}
      displayValue={label}
    />
  )
}
