import { NoiseMonitoringMovedNode } from "src/components/Devices/DeviceSettings/DeviceSettingsNodes"
import { hasIndoorClimateSupport } from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { EditType, ISection } from "src/data/editField/editFieldTypes"
import { TClockTypeMaybe, TTemperatureUnit } from "src/data/user/user"

import {
  IDeviceSettings,
  IDeviceSettingsPayload,
} from "./types/deviceSettingTypes"
import { NOTIFY_ABOUT_NOISE_ID, SectionAdvanced } from "./sectionAdvanced"
import {
  GENERAL_SETTINGS_SECTION,
  OUTDOOR_SENSOR_ID,
  SectionGeneral,
} from "./sectionGeneral"
import {
  HUMIDITY_SECTION_KEY,
  INDOOR_CLIMATE_HUMIDITY_SECTION_KEY,
  SectionHumidity,
  SectionHumidityIndoorClimate,
} from "./sectionHumidity"
import {
  NOISE_MONITORING_SECTION_KEY,
  SectionNoiseMonitoring,
} from "./sectionNoiseMonitoring"
import {
  INDOOR_CLIMATE_TEMPERATURE_SECTION_KEY,
  SectionTemperature,
  SectionTemperatureIndoorClimate,
  TEMPERATURE_SECTION_KEY,
} from "./sectionTemperature"

// Setting sections
const SECTIONS: ISection<IDeviceSettings, IDeviceSettingsPayload>[] = [
  SectionGeneral,
  SectionNoiseMonitoring,
  SectionHumidityIndoorClimate,
  SectionHumidity,
  SectionTemperatureIndoorClimate,
  SectionTemperature,
  SectionAdvanced,
]

/** Return settings with default values */
export function getSections({
  devices,
  indoorClimateAvailable,
  featureFlags,
}: {
  devices: TDevice[]
  indoorClimateAvailable?: boolean
  featureFlags?: Record<string, boolean>
}): ISection<IDeviceSettings, IDeviceSettingsPayload>[] {
  return (
    [...SECTIONS]
      // Replace Noise monitoring sub sections with Noise monitoring moved section if using presets
      .map((section) =>
        updateNoiseMonitoringSection({
          presets: !!featureFlags?.presets,
          section,
        })
      )
      // Hide outdoor sensor setting if no sensor has outdoor support:
      .map(outdoorSensorTransform(devices))
      .filter((section) =>
        filterIndoorClimateSections({
          devices,
          indoorClimateAvailable,
          section,
        })
      )
      // Hide sections without settings:
      .filter(({ contents: settings }) => settings.length)
      // Conditionally show Notify about noise setting for devices with this property set
      .map((section) =>
        showNotifyAboutNoise({
          showNotifyAboutNoise: !!featureFlags?.showNotifyAboutNoise,
          presets: !!featureFlags?.presets,
          section,
        })
      )
  )
}

export function getCurrentConfigs(
  devices: TDevice[],
  extras: {
    clockType: TClockTypeMaybe
    temperature_unit: TTemperatureUnit
  }
): IDeviceSettings[] {
  return devices.map((device) => {
    return { ...device, ...extras }
  })
}

function outdoorSensorTransform(devices: TDevice[]) {
  return (section: ISection<IDeviceSettings, IDeviceSettingsPayload>) => {
    if (section.key !== GENERAL_SETTINGS_SECTION) {
      return section // No need to filter out outdoor sensor setting
    }
    const showOutdoorToggle = devices.every((d) => d.outdoor_support)
    if (showOutdoorToggle) {
      return section // sensor(s) have outdoor support, no filtering needed
    }

    // No support; remove outdoor sensor setting:
    return {
      ...section,
      contents: section.contents.filter(
        (c) =>
          c.type !== EditType.TOGGLE ||
          (c.type === EditType.TOGGLE && c.id !== OUTDOOR_SENSOR_ID)
      ),
    }
  }
}

function filterIndoorClimateSections({
  devices,
  indoorClimateAvailable,
  section,
}: {
  devices: TDevice[]
  indoorClimateAvailable?: boolean
  section: ISection<IDeviceSettings, IDeviceSettingsPayload>
}) {
  const indoorClimateSectionKeys = [
    INDOOR_CLIMATE_HUMIDITY_SECTION_KEY,
    INDOOR_CLIMATE_TEMPERATURE_SECTION_KEY,
  ]

  const otherTemperatureHumidityKeys = [
    HUMIDITY_SECTION_KEY,
    TEMPERATURE_SECTION_KEY,
  ]

  const hasOutdoorDevicesOnly = devices.every((d) => d.placed_outdoors)
  const hasSupportedDevices = devices.every((d) => hasIndoorClimateSupport(d))
  const inBulkMode = devices.length > 1

  const inBulkModeWithUnsupportedDevices = inBulkMode && !hasSupportedDevices

  if (!indoorClimateAvailable) {
    if (indoorClimateSectionKeys.includes(section.key)) {
      return false
    }
    return true
  }

  if (
    inBulkModeWithUnsupportedDevices &&
    !hasOutdoorDevicesOnly &&
    (otherTemperatureHumidityKeys.includes(section.key) ||
      indoorClimateSectionKeys.includes(section.key))
  ) {
    return false
  }

  if (
    inBulkModeWithUnsupportedDevices &&
    indoorClimateSectionKeys.includes(section.key)
  ) {
    return false
  }

  if (
    inBulkModeWithUnsupportedDevices &&
    otherTemperatureHumidityKeys.includes(section.key)
  ) {
    return true
  }

  if (!hasSupportedDevices && indoorClimateSectionKeys.includes(section.key)) {
    return false
  }

  if (
    hasSupportedDevices &&
    otherTemperatureHumidityKeys.includes(section.key)
  ) {
    return false
  }

  return true
}

function updateNoiseMonitoringSection({
  presets,
  section,
}: {
  presets: boolean
  section: ISection<IDeviceSettings, IDeviceSettingsPayload>
}) {
  if (presets && section.key === NOISE_MONITORING_SECTION_KEY) {
    section.contents = [NoiseMonitoringMovedNode]
    return section
  }
  return section
}

function showNotifyAboutNoise({
  showNotifyAboutNoise,
  presets,
  section,
}: {
  showNotifyAboutNoise: boolean
  presets: boolean
  section: ISection<IDeviceSettings, IDeviceSettingsPayload>
}) {
  if (presets && showNotifyAboutNoise) {
    return section
  }

  return {
    ...section,
    contents: section.contents.filter(
      (c) => !(c.type === EditType.DROPDOWN && c.id === NOTIFY_ABOUT_NOISE_ID)
    ),
  }
}
